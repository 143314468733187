import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
	name: 'ReadDocument',
	props: {},
	data: () => ({
		editor: DecoupledDocument,
		editorData: '',
		editorConfig: {
			language: 'es',
			toolbar: {
				items: [],
			},
			allowedContent: false,
		},
		loadingData: false,
		agreement: null,
		position: {},
	}),
	computed: {
		agreementId() {
			return this.$route.params?.agreementId || null;
		},
		breadcrumbs() {
			const moduleRoute =
				this.$route.name === 'ReadPrecontract' ? 'AgreementHome' : 'ContractHome';
			const moduleName =
				this.$route.name === 'ReadPrecontract' ? 'Abastecimiento' : 'Legal';
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: moduleName,
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: moduleRoute,
					},
				},
				{
					text: 'Documento',
					disabled: true,
					href: '/',
				},
			];
		},

		backButtonSettings() {
			let name = null;
			const routeName = this.$route.name;
			if (routeName === 'ReadPrecontract') {
				name = 'ReadAgreement';
			} else if (routeName === 'SupplierReadPrecontract') {
				name = 'SupplierPrecontractDetail';
			} else if (routeName === 'ContractAgreementEditor') {
				name = 'ContractReadAgreement';
			}
			return {
				name,
				params: {
					agreementId: this.$route.params?.agreementId,
				},
			};
		},
	},
	watch: {},
	created() {
		this.readAgreement();
	},
	methods: {
		...mapActions('agreement', ['readAgreementDocument']),

		async readAgreement() {
			if (this.agreementId) {
				this.loadingData = true;
				const { ok, response, error } = await this.readAgreementDocument({
					agreementId: this.agreementId,
					with_values_format: true,
				});
				if (ok) {
					this.agreement = response?.data || null;
					this.editorData =
						response?.data?.last_document_format_with_values || null;
					$EventBus.$emit('showSnack', 'success', response?.message[0]);
					//this.loadListen();
				} else {
					let msm = error?.data?.message;
					msm = Array.isArray(msm) ? msm.join('<br> ') : msm;
					this.showAlert('error', msm);
				}
				this.loadingData = false;
			}
		},

		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},

		onReady(editor) {
			// Insert the toolbar before the editable area.
			editor.enableReadOnlyMode(editor.id);
			editor.ui
				.getEditableElement()
				.parentElement.insertBefore(
					editor.ui.view.toolbar.element,
					editor.ui.getEditableElement()
				);
		},

		loadListen() {
			const that = this;
			document.onmouseup =
				document.onkeyup =
				document.onselectionchange =
					function () {
						document.getElementById('sel').value = that.getSelectionText();
					};
		},

		getSelectionText() {
			var text = '';
			if (window.getSelection) {
				text = window.getSelection().toString();
				const data = {
					general: window.getSelection().focusNode.data,
					palabra: getSelection().toString(),
					inicio: getSelection().baseOffset,
					fin: getSelection().focusOffset,
					base: getSelection().baseNode.parentNode.innerHTML,
				};
				if (data.inicio > data.fin) {
					data.inicio = getSelection().focusOffset;
					data.fin = getSelection().baseOffset;
				}
				this.addMarket(data);
			} /*else if (document.selection && document.selection.type != 'Control') {
				text = document.selection.createRange().text;
			}*/
			return text;
		},

		addMarket(data) {
			if (
				!window.getSelection().getRangeAt(0)?.commonAncestorContainer?.innerHTML
			) {
				let positionParf = this.editorData.indexOf(data.general);
				if (positionParf == -1) {
					positionParf = this.editorData.indexOf(data.base);
				}
				this.position = {
					start: positionParf + data.inicio,
					end: positionParf + data.inicio + data.palabra.length,
					total: this.editorData.length,
					text: data.palabra,
				};
				//console.log();
				//console.log(this.editorData.substring(position.end, position.total));

				// console.log(
				// 	this.editorData.substring(
				// 		positionParf + data.inicio,
				// 		positionParf + data.inicio + data.palabra.length
				// 	)
				// );
				// const text = this.editorData.substring(
				// 	positionParf + data.inicio,
				// 	data.palabra.length
				// );
				// console.log('position:', text);
			}
		},

		addObservation() {
			if (this.position.start && this.position.end) {
				this.editorData =
					this.editorData.substring(0, this.position.start) +
					`<span class="ck-comment-marker" data-comment="e1fc282588-t2">${this.position?.text}</span>` +
					this.editorData.substring(this.position.end, this.position.total);
			}
		},
	},
	beforeDestroy() {},
	components: {},
};
